import React, { useGlobal } from "reactn"
import styled from "styled-components"
import { Button, Icon } from "antd"
import tw from "tailwind.macro"
import Sidebar from "react-sidebar"
import InterestForm from "../components/InterestForm"
import palette from "../design/palette"
import { navigate } from "@reach/router"

const CloseSidebarButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
`
const CloseSidebarButtonIcon = styled(Icon)`
  color: #fff;
`
const Container = styled.aside`
  width: 50vw;
  min-width: 530px;
  @media(max-width: 900px) {
    min-width: 0;
    width: 100vw;
  }
`
const Wrapper = styled.div`
  ${tw`mx-auto`}
  max-width: 500px;
  padding: 0 30px;
`
const Section = styled.section`
  ${tw`mt-12`}
  &:last-of-type {
    ${tw`mb-12`}
  }
`
const SectionTitle = styled.h3`
  ${tw`mb-3 text-xl`}
`
const Menus = styled(Section)`
  ${tw`flex`}
  @media(max-width: 900px) {
    flex-direction: column;
  }
`
const Nav = styled.nav`
  ${tw`flex flex-1 flex-col items-start text-lg`}
  @media(max-width: 900px) {
    &:last-child {
      margin-top: 3rem;
    }
  }
`
const NavItem = styled.a`
  font-family: "Exo 2";
`
const InterestFormTip = styled.p`
  ${tw`mb-8`}
`

const SidebarContent = ({ close }) => {
  const nav = (e, url) => {
    e.preventDefault()
    e.stopPropagation()
    navigate(url)
    close()
  }

  const NavItemInternal = ({ to, children }) => {
    return (
      <NavItem onClick={(e) => nav(e, to)}>
        {children}
      </NavItem>
    )
  }

  return (
    <Container>
      <Wrapper>
        <CloseSidebarButton
          aria-label="Lukk menyen"
          onClick={() => close()}
        >
          <CloseSidebarButtonIcon type="close" />
        </CloseSidebarButton>

        <Menus>
          <Nav role="navigation" aria-label="Prosjektets meny">
            <SectionTitle className="h3">
              Vækerøveien
            </SectionTitle>
            <NavItemInternal to="/#om-prosjektet">
              01. Om prosjektet
            </NavItemInternal>
            <NavItemInternal to="/#standarder-og-kvaliteter">
              02. Kvalitet og standard
            </NavItemInternal>
            <NavItemInternal to="/#egenskaper">
              03. Egenskaper
            </NavItemInternal>
            <NavItemInternal to="/#leilighetene">
              04. Leilighetene
            </NavItemInternal>
            <NavItemInternal to="/#omradet">
              05. Området
            </NavItemInternal>
            <NavItem
              href="/img/vakeroveien-30-salgsoppgave.pdf"
              target="_blank"
            >
              06. Salgsoppgaven
            </NavItem>
          </Nav>

          <Nav role="navigation" aria-label="Bonum meny">
            <SectionTitle className="h3">
              Bonum
            </SectionTitle>
            <NavItem href="https://bonum.no" target="_blank">
              01. Om selskapet
            </NavItem>
            <NavItem href="https://bonum.no/prosjekter" target="_blank">
              02. Flere prosjekter
            </NavItem>
            <NavItem href="https://bonum.no/ansatte" target="_blank">
              03. Våre ansatte
            </NavItem>
            <NavItem href="https://bonum.no/media" target="_blank">
              04. Bonum i media
            </NavItem>
          </Nav>
        </Menus>

        <Section>
          <SectionTitle className="h3">
            Interessert i Vækerøveien?
          </SectionTitle>

          <InterestFormTip className="p">
            Fører du deg opp som interessent for Vækerøveien vil megler kontakte deg så snart som mulig for mer informasjon.
          </InterestFormTip>

          <InterestForm />
        </Section>
      </Wrapper>
    </Container>
  )
}

const OpenSidebarButton = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1;
  display: flex;
  align-items: center;
  button {
    width: 40px;
    height: 40px;
    padding: 0px;
    border-radius: 50%;
    background-color: transparent;
    color: ${palette.lightAccent};
    &:hover hr {
      background-color: #000;
    }
  }
  hr {
    margin-top: 4px;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 55%;
    background-color: #fff;
    height: 1px;
    &:first-child {
      margin-top: 0;
    }
  }
`
const OpenSidebarButtonText = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  background: #000;
  padding: 4px 12px 4px 12px;
  border-radius: 2px;
  font-weight: 500;
  letter-spacing: .5px;
  position: relative;
  margin-right: 20px;
  box-shadow: 0 1px 14px -4px #000;
  &:after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-left-color: #000;
    border-width: 7px;
    margin-top: -7px;
  }
`

const PageSidebar = ({ children }) => {
  const [ sidebarIsOpen, setSidebarIsOpen ] = useGlobal("sidebarIsOpen")

  return (
    <Sidebar
      open={sidebarIsOpen}
      onSetOpen={setSidebarIsOpen}
      styles={{
        sidebar: {
          backgroundImage: "linear-gradient(80deg, #000000, #2d2513)"
        }
      }}
      sidebar={(
        <SidebarContent close={() => setSidebarIsOpen(false)} />
      )}
    >
      <OpenSidebarButton>
        <OpenSidebarButtonText>
          Meld interesse
        </OpenSidebarButtonText>
        <Button
          aria-label="Åpne menyen"
          onClick={() => setSidebarIsOpen(!sidebarIsOpen)}
        >
          <hr />
          <hr />
          <hr />
        </Button>
      </OpenSidebarButton>

      {children}
    </Sidebar>
  )
}

export default PageSidebar
