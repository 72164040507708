import React, { Component } from "react"
import { Modal, Button, Switch, Spin } from "antd"
import styled from "styled-components"

const GdprModal = styled(Modal)`
  max-width: 385px;
  .ant-modal-body {
    padding-bottom: 10px;
  }
  .ant-switch:not(.ant-switch-checked) {
    background-color: rgba(0, 0, 0, 0.25)
  }
  p {
    margin-bottom: 30px;
  }
`

const ConsentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
`

const ConsentLabel = styled.label`
  margin-left: 10px;
`

const GdprButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  button:last-of-type {
    margin-top: 10px;
    margin-left: 0;
    color: #000;
    box-shadow: none;
  }
`

class GdprDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      consents: props.consents.map(
        (consent) => ({ ...consent, value: false })
      ),
      hasRequiredConsents: false
    }
  }

  onChangeConsent = (index, value) => {
    let hasRequiredConsents = false
    const consents = this.state.consents
    consents[index].value = value

    const accepts = consents.filter(
      ({ value, required }) => value === true && required === true
    )

    const required = consents.filter(
      ({ required }) => required === true
    )

    if(accepts.length >= required.length) {
      hasRequiredConsents = true
    }

    this.setState({ ...this.state, consents, hasRequiredConsents })
  }

  render() {
    return (
      <GdprModal
        title="Vennligst les før du fortsetter"
        visible={this.props.shown}
        onOk={this.props.onAccept}
        onCancel={this.props.onDeny}
        footer={null}
      >
        <Spin spinning={this.props.isLoading} tip="Sender...">
          <p>{this.props.description}</p>

          <p>Du kan lese mer om <a href="https://bonum.no/personvern" target="_blank">vårt forhold til personvern</a>, for å finne ut om hvordan vi best ivaretar din informasjon.</p>

          {this.props.consents.map((consent, index) => (
            <ConsentContainer key={index}>
              <Switch
                id={`consent-${index}`}
                name={`consent-${index}`}
                onChange={(value) => this.onChangeConsent(index, value)}
              />

              <ConsentLabel htmlFor={`consent-${index}`}>
                {consent.question}
              </ConsentLabel>
            </ConsentContainer>
          ))}

          <GdprButtons>
            <Button
              block
              type="primary"
              onClick={this.props.onAccept}
              disabled={!this.state.hasRequiredConsents}
            >
              Jeg aksepterer
            </Button>

            <Button
              block
              type="text"
              onClick={this.props.onDeny}
            >
              Nei takk
            </Button>
          </GdprButtons>
        </Spin>
      </GdprModal>
    )
  }
}

export default GdprDialog
