import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, image, pathname, article }) => {
  const { site } = useStaticQuery(query)

  const {
    siteMetadata: {
      defaultTitle,
      titleTemplate,
      defaultDescription,
      siteUrl,
      defaultImage,
      twitterUsername
    }
  } = site

  const meta = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname || "/"}`
  }

  // const associatedMedia = [
  //   {
  //     text: this.project.image.caption || this.project.image.alt,
  //     thumbnailUrl: this.project.image.url,
  //     contentUrl: this.project.image.url
  //   }
  // ]

  const structuredData = [{
    "@context": "http://schema.org/",
    "@type": "ApartmentComplex",
    "name": meta.title,
    "description": meta.description,
    "image": meta.image,
    "url": meta.url,
    "telephone": "22232385",
    "sameAs": `https://bonum.no/prosjekter/vakeroveien-30`
  }
  // , {
  //   "@context": "http://schema.org/",
  //   "@type": "ImageGallery",
  //   "url": this.project.url,
  //   "associatedMedia": JSON.stringify(associatedMedia)
  // }
  ]

  return (
    <>
      <Helmet
        title={meta.title}
        titleTemplate={titleTemplate}
      >
        {meta.title && <meta name="twitter:title" content={meta.title} />}
        {meta.title && <meta property="og:title" content={meta.title} />}

        {meta.description && <meta name="description" content={meta.description} />}
        {meta.description && <meta property="og:description" content={meta.description} />}
        {meta.description && <meta name="twitter:description" content={meta.description} />}

        {meta.image && <meta name="image" content={meta.image} />}
        {meta.image && <meta property="og:image" content={meta.image} />}
        {meta.image && <meta name="twitter:image" content={meta.image} />}

        {meta.url && <meta property="og:url" content={meta.url} />}

        {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}
        {twitterUsername && <meta name="twitter:card" content="summary_large_image" />}

        <meta property="og:type" content="website" />
        <meta property="fb:app_id" content="1253609801428791" />

        {structuredData.map((data, index) => (
          <script
            key={index}
            type="application/ld+json"
          >
            {JSON.stringify(data)}
          </script>
        ))}
      </Helmet>
    </>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`
