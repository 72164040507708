import { createGlobalStyle } from "styled-components"
import palette from "./palette"

export default createGlobalStyle`
  html {
    .ant-modal,
    .ant-modal-body,
    .ant-form,
    .ant-form-item,
    .ant-input-affix-wrapper,
    .ant-input {
      color: inherit;
      font: inherit;
    }

    .ant-modal-title {
      font-family: "Exo 2", serif;
      font-size: 21px;
    }

    .ant-modal-mask {
      background-color: rgba(0, 0, 0, 0.85);
    }

    .ant-modal {
      color: #000;
      background-color: #fff;
    }

    .ant-modal-content {
      box-shadow: none;
      font-size: 0.9rem;
    }

    .ant-modal.fullscreen {
      margin: 0;
      padding: 0;
      min-width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow-x: hidden;
      .ant-modal-content {
        border-radius: 0;
        box-shadow: none;
      }
    }

    .ant-input {
      height: 36px;
      background: none;
      font-size: 16px;
      border-radius: 3px;
    }

    .ant-switch {
      background-color: rgba(255, 255, 255, 0.25);
    }

    .ant-switch-checked {
      background-color: ${palette.lightAccent};
    }

    .ant-form-item-label > label {
      color: ${palette.fg};
    }

    .ant-input-affix-wrapper .ant-input:not(:first-child) {
      padding-left: 36px;
    }

    .ant-btn {
      font: inherit
      height: auto;
      border-radius: 3px;
      border: 2px solid #fff;
      color: #fff;
      background-color: transparent;
      padding: 12px 32px;
      line-height: 1;
    }

    .ant-btn:hover,
    .ant-btn:focus {
      background-color: ${palette.lightAccent};
      border-color: ${palette.lightAccent};
      color: ${palette.darkAccent};
    }

    .ant-btn-primary {
      background-color: ${palette.lightAccent};
      border-color: ${palette.lightAccent};
      color: ${palette.bg};
      &:hover {
        background-color: ${palette.bg};
        border-color: ${palette.bg};
        color: ${palette.lightAccent};
      }
    }

    .ant-form-explain {
      font-size: 0.8rem;
      font-weight: 500;
    }
  }
`
