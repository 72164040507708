import React from "react"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

import ResetStyles from "../design/resets"
import GlobalStyles from "../design/global"
import Overrides from "../design/overrides"
import palette from "../design/palette"

import SEO from "../components/SEO"
import PageSidebar from "../components/PageSidebar"
import PageFooter from "../components/PageFooter"

const DefaultLayout = ({ data, children }) => (
  <div id="default_template">
    <SEO />
    <Helmet>
      <html lang="no" />
      <meta name="theme-color" content={palette.fg} />
      <link rel="icon" type="image/png" href="/img/favicon.png" />
    </Helmet>

    <ResetStyles />
    <GlobalStyles />
    <Overrides />

    <PageSidebar>
      {children}
      <PageFooter />
    </PageSidebar>
  </div>
)

const query = graphql`
  query HeadingQuery {
    site {
      siteMetadata {
        title,
        description
      }
    }
  }
`

const TemplateWrapper = ({ children }) => (
  <StaticQuery
    query={query}
    render={(data) => <DefaultLayout data={data} children={children} />}
  />
)

export default TemplateWrapper
