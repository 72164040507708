import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import device from "../design/device"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 20px;
  bottom: 20px;
  padding: 20px;
  z-index: 2;
  ${device.phone`
    position: static;
    flex-direction: row;
    justify-content: center;
    a:not(:first-of-type) {
      margin-left: 20px;
    }
  `}
`

const A = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  &, &:hover {
    box-shadow: none;
    border-bottom: none;
    background-color: transparent;
  }
  &:hover {
    opacity: 0.5;
  }
`

const SocialMediaIcon = ({ children, src, link, title }) => (
  <A href={link} target="_blank" rel="noopener" title={title}>
    {src.childImageSharp
      ? <Img fixed={src.childImageSharp.fixed} alt={title} />
      : <img src={src} style={{ maxWidth: 16 }} alt={title} />
    }
    <span hidden>{children}</span>
  </A>
)

const SocialMedia = ({ socialMedias }) => (
  <Container>
    {socialMedias.map((socialMedia, index) => (
      <SocialMediaIcon
        src={socialMedia.imageSrc}
        link={socialMedia.link}
        title={`Bonum på ${socialMedia.label}`}
        key={index}
      >
        Bonum på {socialMedia.label}
      </SocialMediaIcon>
    ))}
  </Container>
)

export default SocialMedia
