import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import SocialMedia from "./SocialMedia"
import tw from "tailwind.macro"
import palette from "../design/palette"
// import device from "../design/device"

const Footer = styled.footer`
  ${tw`text-center pt-12 pb-6`}
`

const FooterNav = styled.nav`${tw`mx-auto`}`
const MainMenu = styled.div`
  ${tw`mb-3`}
  @media(max-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    a {
      margin-bottom: 10px;
    }
  }
`
const MainMenuItem = styled.a`
  ${tw`border-b-0 text-sm text-white mx-4`};
  &:hover {
    color: ${palette.lightAccent};
  }
`

const SocialMenu = styled.div`${tw`my-3`}`
const SocialMenuItem = styled.a`
  ${tw`border-b-0 text-black`}
  &:hover {
    color: ${palette.lightAccent};
  }
`

const PageFooter = (props) => {
  const socialMedias = [{
    label: "Instagram",
    link: "https://www.instagram.com/bonum.no",
    imageSrc: "/img/instagram.png"
  }, {
    label: "Facebook",
    link: "https://www.facebook.com/BonumNO",
    imageSrc: "/img/facebook.png"
  }]

  return (
    <Footer className="pad">
      <FooterNav role="navigation" className="md-wrapper">
        <MainMenu>
          <MainMenuItem href="https://bonum.no" target="_blank">Selskap</MainMenuItem>
          <MainMenuItem href="https://bonum.no/utvikling" target="_blank">Utvikling</MainMenuItem>
          <MainMenuItem href="https://bonum.no/prosjekter" target="_blank">Prosjekter</MainMenuItem>
          <MainMenuItem href="https://bonum.no/entreprise" target="_blank">Entreprise</MainMenuItem>
          <MainMenuItem href="https://bonum.no/ansatte" target="_blank">Ansatte</MainMenuItem>
          <MainMenuItem href="https://bonum.no/karriere" target="_blank">Karriere</MainMenuItem>
          <MainMenuItem href="https://bonum.no/media" target="_blank">Media</MainMenuItem>
        </MainMenu>

        {/* <SocialMenu>
          <SocialMenuItem
            href="https://facebook.com/BonumNO"
            target="_blank"
            title="Bonum på Facebook"
            ref="noopener"
          >
            <span style="display:none;">Bonum på Facebook</span>
            <i class="ion-logo-facebook" />
          </SocialMenuItem>
          <SocialMenuItem
            href="https://twitter.com/BonumAS"
            target="_blank"
            title="Bonum på Twitter"
            ref="noopener"
          >
            <span style="display:none;">Bonum på Twitter</span>
            <i class="ion-logo-twitter" />
          </SocialMenuItem>
          <SocialMenuItem
            href="https://instagram.com/bonum.no"
            target="_blank"
            title="Bonum på Instagram"
            ref="noopener"
          >
            <span style="display:none;">Bonum på Instagram</span>
            <i class="ion-logo-instagram" />
          </SocialMenuItem>
          <SocialMenuItem
            href="https://linkedin.com/company/Bonum-AS"
            target="_blank"
            title="Bonum på LinkedIn"
            ref="noopener"
          >
            <span style="display:none;">Bonum på LinkedIn</span>
            <i class="ion-logo-linkedin" />
          </SocialMenuItem>
        </SocialMenu> */}
      </FooterNav>

      <small className="small">
        Bonum © 2019. All rights reserved. <a href="https://bonum.no/personvern" target="_blank">Personvern.</a>
      </small>

      <SocialMedia
        socialMedias={socialMedias}
      />
    </Footer>
  )
}

export default PageFooter
