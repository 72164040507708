import { createGlobalStyle } from "styled-components"
import palette from "./palette"
import device from "./device"

export default createGlobalStyle`
  @import url("https://fonts.googleapis.com/css?family=Exo+2:200,300,400,500,600,700|Quicksand:400,500|Ropa+Sans:400");

  html {
    body {
      color: ${palette.fg};
      background-image: linear-gradient(85deg, #000000, #2f2716);
      font-family: "Quicksand", sans-serif;
      font-size: 17px;
      font-weight: 400;
      line-height: 1.7;
      -webkit-font-smoothing: auto;
    }

    h1, h2, h3, h4, h5, h6 {
      font-family: "Ropa Sans", sans-serif;
      color: inherit;
      line-height: 1.25;
      font-weight: 300;
      margin: 0;
    }

    h1 {
      font-size: 100px;
      font-weight: 600;
      line-height: 1;
    }

    h2 {
      font-size: 44px;
    }

    h3 {
      font-size: 24px;
    }

    li {
      margin-left: 15px;
      &:before {
        font-size: 32px;
        content: "· ";
        line-height: 20px;
        vertical-align: middle;
      }
    }

    small {
      font-size: 14px;
    }

    button {
      font: inherit;
      color: inherit;
      cursor: pointer;
      span {
        color: inherit;
      }
    }

    button + button {
      margin-left: 30px;
    }

    p {
      margin-bottom: 1rem;
    }

    a {
      cursor: pointer;
      color: ${palette.lightAccent};
    }

    a:hover {
      color: ${palette.fg};
    }

    button:hover {
      color: ${palette.darkAccent};
    }

    small a {
      font-weight: 500;
    }

    img {
      max-width: 100%;
    }

    strong {
      font-weight: 600;
    }

    .ant-input::-webkit-input-placeholder,
    .ant-input:-moz-placeholder,
    .ant-input::-moz-placeholder,
    .ant-input:-ms-input-placeholder {
      font-size: 0.9rem;
    }
  }

  .ant-input::-webkit-input-placeholder {
    font-size: 14px;
  }

  .drop-caps > *:first-letter {
    float: left;
    font-size: 58px;
    line-height: 44px;
    padding-top: 4px;
    padding-right: 8px;
  }

  .accent-left {
    position: relative;
    &:before {
      content: "";
      background-color: ${palette.lightAccent};
      width: 25px;
      height: 3px;
      top: 50%;
      left: -50px;
      position: absolute;
      margin-top: -1.5px;
    }
  }

  .cc-2 {
    column-count: 2;
  }

  .lg-wrapper,
  .md-wrapper,
  .sm-wrapper,
  .xs-wrapper {
    width: 100%;
  }

  .lg-wrapper {
    max-width: 1300px;
  }
  .md-wrapper {
    max-width: 1000px;
  }
  .sm-wrapper {
    max-width: 600px;
  }
  .xs-wrapper {
    max-width: 450px;
  }

  .sep {
    background-color: ${palette.lightAccent};
  }
  .sep--h {
    height: 3px;
    max-width: 25px;
    width: 100%;
  }

  .pad {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media(max-width: 1200px) {
    .accent-left:before {
      top: -15px;
      left: 0;
    }
  }

  @media(max-width: 900px) {
    html h1 {
      font-size: 10vw;
    }
    html h2 {
      font-size: 28px;
    }
  }

  @media(max-width: 600px) {
    .pad {
      padding: 60px 20px;
    }
    figcaption.pad {
      padding-top: 0;
      padding-bottom: 0;
    }
    .cc-2 {
      column-count: 1;
    }
  }

  @media(max-width: 400px) {
    // html h1 {
    //   font-size: 16vw;
    // }
    .pad {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`
