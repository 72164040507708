import React, { Component } from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import { Switch, Button, Form, Icon, Input, Row, Col, notification } from "antd"
import { navigate } from "gatsby-link"
import GdprDialog from "./GdprDialog"

const { TextArea } = Input

const FormElement = styled(Form)`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`
const InputIcon = styled(Icon)`color: rgba(255, 255, 255, .75) !important;`
const SubmitButton = styled(Button)`${tw`mt-4`}`
const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
const SwitchLabel = styled.label`margin-left: 10px;`

const API_URL = process.env.GATSBY_API_URL
const PROJECT_ID = process.env.GATSBY_PROJECT_ID

class InterestForm extends Component {
  state = {
    showGdprDialog: false,
    isLoading: false
  }

  openGdprDialog = () => {
    this.props.form.validateFields((error, values) => {
      if(error) {
        return notification.error({
          message: "Oops!",
          description: "Vennligst fyll inn alle påkrevde felt"
        })
      }
      this.setState({ showGdprDialog: true })
    })
  }

  closeGdprDialog = () => {
    this.setState({ showGdprDialog: false })
  }

  acceptGdprDialog = async () => {
    await this.submitInterest()
    this.closeGdprDialog()
  }

  denyGdprDialog = () => {
    this.closeGdprDialog()
  }

  submitInterest = async () => {
    this.setState({ ...this.state, isLoading: true })
    const values = this.props.form.getFieldsValue()

    const data = {
      source: "bonum",
      customer: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber
      },
      message: values.message,
      options: {
        sms: values.sms,
        tips: values.tips,
        newsletter: values.newsletter
      }
    }

    try {
      const res = await fetch(`${API_URL}/hovedsider/projects/${PROJECT_ID}/interest`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      })

      const result = await res.json()

      if(!res.ok) {
        throw new Error(result.message || "En ukjent feil oppstod")
      }

      navigate("/thanks")
    } catch(e) {
      notification.error({
        message: "Oops!",
        description: e.message
      })
    }

    this.setState({ ...this.state, isLoading: false })
  }

  render() {
    const { getFieldDecorator } = this.props.form

    return (
      <>
        <FormElement name="interest-form">
          <Row gutter={20}>
            <Col
              lg={{ span: 12 }}
              md={{ span: 24 }}
            >
              <Form.Item label="Fornavn">
                {getFieldDecorator("firstName", {
                  rules: [{
                    required: true,
                    message: "Vennligst skriv inn ditt fornavn"
                  }]
                })(
                  <Input
                    prefix={<InputIcon type="user" />}
                    placeholder="Skriv inn fornavn"
                    name="firstName"
                  />
                )}
              </Form.Item>
            </Col>

            <Col
              lg={{ span: 12 }}
              md={{ span: 24 }}
            >
              <Form.Item label="Etternavn">
                {getFieldDecorator("lastName", {
                  rules: [{
                    required: true,
                    message: "Vennligst skriv inn ditt etternavn"
                  }]
                })(
                  <Input
                    prefix={<InputIcon type="user" />}
                    placeholder="Skriv inn etternavn"
                    name="lastName"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col
              lg={{ span: 12 }}
              md={{ span: 24 }}
            >
              <Form.Item label="E-post">
                {getFieldDecorator("email", {
                  rules: [{
                    required: true,
                    message: "Vennligst skriv inn din e-post adresse",
                    type: "email"
                  }]
                })(
                  <Input
                    prefix={<InputIcon type="mail" />}
                    placeholder="Skriv inn e-post"
                    name="email"
                  />
                )}
              </Form.Item>
            </Col>

            <Col
              lg={{ span: 12 }}
              md={{ span: 24 }}
            >
              <Form.Item label="Telefonnummer">
                {getFieldDecorator("phoneNumber")(
                  <Input
                    prefix={<InputIcon type="mail" />}
                    placeholder="Skriv inn telefonnummer"
                    name="phoneNumber"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Melding">
            {getFieldDecorator("message")(
              <TextArea
                prefix={<InputIcon type="mail" />}
                placeholder="Skriv inn en melding"
                autosize={{ minRows: 2 }}
              />
            )}
          </Form.Item>

          <Form.Item>
            <SwitchContainer>
              {getFieldDecorator("sms", {
                valuePropName: "checked",
                initialValue: false
              })(
                <Switch id="sms" name="sms" />
              )}
              <SwitchLabel htmlFor="sms">
                Jeg ønsker budvarsel på SMS
              </SwitchLabel>
            </SwitchContainer>
          </Form.Item>

          <Form.Item>
            <SwitchContainer>
              {getFieldDecorator("tips", {
                valuePropName: "checked",
                initialValue: false
              })(
                <Switch id="tips" name="tips" />
              )}
              <SwitchLabel htmlFor="tips">
                Jeg ønsker tips om liknende eiendommer per e-post
              </SwitchLabel>
            </SwitchContainer>
          </Form.Item>

          <Form.Item>
            <SwitchContainer>
              {getFieldDecorator("newsletter", {
                valuePropName: "checked",
                initialValue: false
              })(
                <Switch id="newsletter" name="newsletter" />
              )}
              <SwitchLabel htmlFor="newsletter">
                Jeg ønsker å motta nyhetsbrev per e-post
              </SwitchLabel>
            </SwitchContainer>
          </Form.Item>

          <SubmitButton
            type="primary"
            onClick={this.openGdprDialog}
          >
            Meld interesse
          </SubmitButton>
        </FormElement>

        <GdprDialog
          shown={this.state.showGdprDialog}
          onAccept={this.acceptGdprDialog}
          onDeny={this.denyGdprDialog}
          isLoading={this.state.isLoading}
          description="Dette skjemaet samler inn ditt navn, din e-post adresse og ditt telefonnummer. Da kan enten vi eller vår megler kontakte deg under behandlingen av henvendelsen."
          consents={[{
            question: "Jeg samtykker til at Bonum kan samle på mitt navn, e-post adresse og telefonnummer.",
            required: true
          }, {
            question: "Jeg samtykker til at jeg kan bli kontaktet av megler.",
            required: true
          }]}
        />
      </>
    )
  }
}

export default Form.create({})(InterestForm)
